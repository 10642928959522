<template>
  <div class="about" style="font-family: monospace, serif">
    <h5>Impressum</h5>
    <p>Pflichtangaben nach §5 TMG</p>
    <p>
      Daniel Tretter<br>Bundesallee 46a<br>10715 Berlin<br>
      <br>email: k@tretter.dev<br>tel: 030 40698622<br>
      <br>USt.Id.Nr.: DE323717920
    </p>
  </div>
</template>
